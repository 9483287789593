import { OWNER_OPTIONS } from "frontend/boards-grid/search-section/consts";
import { RecentSelected, SearchFilter, SearchItem } from "frontend/boards-grid/search-section/types";
import { Board } from "shared/datamodel/schemas";

/**
 * Sorts an array of search items by recent selections.
 * If a search item matches a document ID in recent selections, it is prioritized.
 * Items with the most recent selection time appear first.
 */
export const sortItemsByRecentSelections = (
  searchItems: SearchItem[],
  recentSelections: RecentSelected[],
  includeAll?: boolean
): SearchItem[] => {
  // Create a lookup for recentSelections based on documentId
  const recentMap = new Map<string, Date>(recentSelections.map((item) => [item.documentId, new Date(item.selectedAt)]));
  // Sort searchItems based on the order in recentSelections, if found
  return [...searchItems]
    .filter((item) => {
      return includeAll || recentMap.has(item.documentId);
    })
    .sort((a, b) => {
      const recentA = recentMap.get(a.documentId);
      const recentB = recentMap.get(b.documentId);

      // If both documents are found, sort by recent selection time
      if (recentA && recentB) {
        return recentB.getTime() - recentA.getTime();
      }

      // If only one document is found, prioritize the found one
      if (recentA) return -1;
      if (recentB) return 1;

      // If neither document is found, keep the original order
      return 0;
    });
};

export const boardsToItems = (boards: Board[]): SearchItem[] => {
  return boards.map((b) => {
    return {
      documentId: b.documentId,
      name: b.name,
      lastUpdated: b.updatedAt,
      isOwner: b.isOwner,
      breadcrumbs: [b.teamName, b.projectName].filter((b) => !!b),
    };
  });
};

export const getFilterValue = (filter: SearchFilter) => {
  if (filter.type === "date") {
    if (filter.value?.label) {
      return filter.value?.label;
    }
    return `${filter.value?.startDate} - ${filter.value?.endDate}`;
  }
  return filter.value;
};

/**
 * Filters an array of search items based on multiple filter conditions.
 * Each search item must satisfy all filter conditions to be included in the result.
 */
export const filterItemByFilters = (filters: SearchFilter[]) => {
  return (item: SearchItem) => {
    return filters.every((filter) => {
      if (filter.type === "text") {
        return item.name.toLowerCase().includes(filter.value?.toLowerCase() ?? "");
      }
      if (filter.type === "owner") {
        return (
          !filter.value || (item.isOwner && filter.value === OWNER_OPTIONS[1]) || filter.value === OWNER_OPTIONS[0]
        );
      }

      if (filter.type === "date") {
        if (!filter.value) {
          return true;
        }
        const dateObj = new Date(item.lastUpdated);
        const startObj = new Date(filter.value?.startDate ?? "");
        const endObj = new Date(filter.value?.endDate ?? "");

        return dateObj >= startObj && dateObj <= endObj;
      }
    });
  };
};
