export const SpacerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" fill="none" viewBox="0 0 10 7">
    <path
      fill="#848199"
      fillRule="evenodd"
      d="M6.466 0L10 3.315l-.608.591L6.48 6.75l-.621-.58 2.47-2.412-8.027.009L.3 2.939l8.05-.008L5.859.59 6.466 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);
