import React, { useEffect, useRef, useState } from "react";
import style from "./add-canvases-to-ptoject-modal.module.css";
import StyledButton from "frontend/ui-components/styled-button";
import { Board } from "shared/datamodel/schemas";
import CollaborationHeader from "../collaboration-header";
import { useDebounce } from "usehooks-ts";
import NoCanvasesEmptystate from "../../../boards-grid/no-canvases-empty-state";
import SearchEmptyState from "../empty-state/search-empty-state";

export default function AddCanvasesToProjectModel({
  onDismiss,
  onCreateProject,
  boards,
}: {
  onDismiss: any;
  onCreateProject?: (canvases: Board[], newCanvasForProject?: boolean) => void;
  boards: Board[];
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const [searchResults, setSearchResults] = useState<Board[]>([]);

  const modalRef = useRef(null);

  const [checked, setChecked] = useState<Board[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const filteredBoards: Board[] =
        searchTerm && boards?.values()
          ? (Array.from(boards.values()).filter((board) => {
              const name = board.name.toLowerCase();
              return name?.includes(searchTerm);
            }) as Board[])
          : (Array.from(boards.values()) as Board[]) ?? [];
      filteredBoards && setSearchResults(filteredBoards);
    } else {
      setSearchResults(boards);
    }
  }, [debouncedSearchTerm, boards]);

  // Add/Remove checked item from list
  const handleCheck = (boardId: number) => {
    var updatedList = [...checked];
    if (updatedList.map((b) => b.id).includes(boardId)) {
      updatedList.splice(checked.map((b) => b.id).indexOf(boardId), 1);
    } else {
      const newBoard = boards.find((board) => board.id === boardId);
      newBoard && updatedList.push(newBoard);
    }
    setChecked(updatedList);
  };

  const handleCheckAll = () => {
    setChecked(checkAll ? [] : boards);
    setCheckAll(!checkAll);
  };

  function renderCard(board: Board) {
    const backgroundImage = board.thumbnail ?? `/images/canvas-placeholder-new-${board.id % 4}.jpg`;
    return (
      <div key={board.id} data-testid={board.name} onClick={() => handleCheck(board.id)}>
        <div
          className={style.boardImage}
          onClick={() => handleCheck(board.id)}
          style={{
            background: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "100% 100%",
          }}
        >
          <input
            value={board.id}
            key={board.id}
            type="checkbox"
            onChange={() => handleCheck(board.id)}
            checked={checked.map((b) => b.id).includes(board.id)}
            autoComplete="off"
          />
          <div className={style.titleContainer}>
            <div className={style.boardName}>{board.name}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={style.container} ref={modalRef}>
      <div style={{ padding: "25px 25px 0 25px" }}>
        <CollaborationHeader
          title={"Add canvases to your project"}
          subtitle={boards.length > 0 ? "These canvases will be relocated to this project." : undefined}
          onDismiss={onDismiss}
        />
      </div>
      {boards.length > 0 ? (
        <>
          <div className={style.searchSection}>
            <div className={style.selectAll} onClick={handleCheckAll}>
              <input type="checkbox" onChange={handleCheckAll} checked={checkAll} />
              <span className={style.chooseAllText}>All</span>
            </div>
            <div className={style.searchContainer}>
              <input
                type="text"
                autoComplete="off"
                value={searchTerm}
                placeholder="Search canvases"
                autoFocus
                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              />
            </div>
          </div>
          <div className={style.divider}></div>
          <div className={style.boardsContainer}>
            {searchResults.length > 0 || searchTerm === "" ? (
              <div className={style.boards}>{searchResults.map(renderCard)}</div>
            ) : (
              <SearchEmptyState searchTerm={searchTerm} subtitle={"did not match any canvas name"} />
            )}
          </div>
        </>
      ) : (
        <NoCanvasesEmptystate
          subtitle={"No canvases yet!\n Create your first canvas for this project"}
          onDismiss={() => {
            onCreateProject && onCreateProject([], true);
            onDismiss();
          }}
        />
      )}
      <div className={style.divider}></div>
      <div className={style.footer}>
        <span
          onClick={() => {
            onCreateProject && onCreateProject([]);
            onDismiss();
          }}
          className={style.link}
        >
          Skip for now
        </span>
        <StyledButton
          title={"Add canvases"}
          onClick={() => {
            onCreateProject && onCreateProject(checked);
            onDismiss();
          }}
          enabled={boards.length > 0 && checked.length > 0}
        />
      </div>
    </div>
  );
}
