import { ReactNode, useEffect, useRef, useState } from "react";

/**
 * Grid component that renders its items in batches from memory.
 * This is useful for grids that contain a lot of elements and cause have rendering effects.
 */
export const BatchedItemsGrid = <T,>({ items, batchSize, gridContainerClass, cellRender }: {
    items: Array<T>, batchSize: number, gridContainerClass: any, cellRender: (item: T, index: number) => ReactNode
}) => {
    const gridRef = useRef<HTMLDivElement>(null);
    const loadingRef = useRef<HTMLDivElement>(null);
    const [visibleBatchSize, setVisibleBatchSize] = useState(batchSize);

    // Intersection Observer for infinite scroll
    useEffect(() => {
        if (!items?.length) return;
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && visibleBatchSize < items.length) {
                    setVisibleBatchSize(prevSize => Math.min(prevSize + batchSize, items?.length));
                }
            },
            { threshold: 0.1, rootMargin: "200px" }
        );

        const currentLoadingRef = loadingRef.current;
        if (currentLoadingRef) {
            observer.observe(currentLoadingRef);
        }

        return () => {
            if (currentLoadingRef) {
                observer.unobserve(currentLoadingRef);
            }
            observer.disconnect();
        };
    }, [items, visibleBatchSize]);

    const visibleItems = items.slice(0, visibleBatchSize);
    return (
        <div ref={gridRef}>
            <div className={gridContainerClass}>
                {visibleItems.map((item, index) => cellRender(item, index))}
            </div>
            {visibleItems.length < items.length && (
                <div className="grid-loading" ref={loadingRef} />
            )}
        </div>
    )
};