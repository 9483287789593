import React, { CSSProperties, Fragment, useRef, useState } from "react";
import { format } from "timeago.js";
import { SearchItem as SearchItemType } from "frontend/boards-grid/search-section/types";
import { SpacerIcon } from "frontend/icons/spacer";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import styles from "./search-item.module.css";

type SearchItemProps = SearchItemType & {
  onClick: (documentId: string) => void;
  style: CSSProperties;
};

const TOOLTIP_STYLE: CSSProperties = {
  width: "fit-content",
  backgroundColor: "#ebedf3",
  color: "#113357",
  padding: "0 12px",
  boxShadow: "0px 2px 4px 0px #00000026",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  font: "400 16px Poppins",
  whiteSpace: "nowrap",
  height: 36,
  transform: "translate(150px)",
};

export const SearchItem = ({ documentId, name, breadcrumbs, lastUpdated, onClick, style }: SearchItemProps) => {
  const [tooltip, setTooltip] = useState<string | null>(null);
  const nameRef = useRef<HTMLDivElement | null>(null);

  const onItemClick = () => {
    onClick(documentId);
  };

  return (
    <div className={styles.wrapper} onClick={onItemClick} style={style}>
      <div
        className={styles.boardName}
        ref={nameRef}
        onMouseEnter={() => {
          const isTextOverflowing = nameRef.current && nameRef.current.scrollWidth > nameRef.current.clientWidth;
          isTextOverflowing ? setTooltip(name) : setTooltip(null);
        }}
        onMouseLeave={() => setTooltip(null)}
      >
        {name}
      </div>
      {tooltip && (
        <Tooltip relativeTo={nameRef} side={"bottom"} label={name} customStyle={TOOLTIP_STYLE} withArrow={false} />
      )}
      <div className={styles.breadcrumbs}>{createBreadcrumbs(breadcrumbs)}</div>
      <div className={styles.detail}>Edited {format(lastUpdated)}</div>
    </div>
  );
};

const createBreadcrumbs = (breadcrumbs: SearchItemType["breadcrumbs"]) => {
  return breadcrumbs.map((breadcrumb, i) => {
    return (
      <Fragment key={i}>
        <span className={styles.breadcrumb}>{breadcrumb}</span>
        {i < breadcrumbs.length - 1 ? <SpacerIcon /> : ""}
      </Fragment>
    );
  });
};
