import React, { useContext, useMemo, useState } from "react";
import { MAX_RECENT_ITEMS, useRecentSelected } from "frontend/boards-grid/search-section/hooks/use-recent-selected";
import { SearchFilter, SearchItem } from "frontend/boards-grid/search-section/types";
import {
  boardsToItems,
  filterItemByFilters,
  sortItemsByRecentSelections,
} from "frontend/boards-grid/search-section/utils";
import useStateValue from "frontend/state/value";
import tracking from "frontend/tracking";
import { SEARCHBAR_TRACKING_ACTIONS } from "frontend/boards-grid/search-section/events";
import { assoc, reduce } from "rambda";

const convertArrayToObject = reduce((acc, { type, value }: { type: any; value?: any }) => assoc(type, value, acc), {});

type SearchContextProps = {
  filters: SearchFilter[];
  items: { all: SearchItem[]; recent: SearchItem[] };
  onSelected: (documentId: string) => void;
  onFilterChange: (type: SearchFilter["type"], value: any) => void;
};

type SearchProviderProps = {
  children: React.ReactNode;
};

const SearchContext = React.createContext<SearchContextProps>({
  filters: [],
  items: { all: [], recent: [] },
  onSelected: () => {},
  onFilterChange: () => {},
});

export const useSearchContext = () => useContext(SearchContext);

export const SearchContextProvider = ({ children }: SearchProviderProps) => {
  const [{ boards }] = useStateValue();

  const [filters, setFilters] = useState<SearchFilter[]>([]);
  const { recentSelected, onSelected } = useRecentSelected();

  const itemsList = useMemo(() => {
    const items = boardsToItems(boards);
    const sortedItems = sortItemsByRecentSelections(items, recentSelected).slice(0, MAX_RECENT_ITEMS);
    if (filters.length) {
      tracking.trackAnalyticsEvent(SEARCHBAR_TRACKING_ACTIONS.searchbar_new_search, {
        ...convertArrayToObject(filters),
        totalResults: items.length,
      });
    }
    return {
      all: sortItemsByRecentSelections(items.filter(filterItemByFilters(filters)), recentSelected, true),
      recent: sortedItems,
    };
  }, [boards, filters]);

  const onFilterChange = (type: "text" | "owner" | "date", value: any) => {
    const newFilters = structuredClone(filters);
    const existingTextFilterIndex = newFilters.findIndex((filter) => filter.type === type);

    if (existingTextFilterIndex !== -1) {
      newFilters[existingTextFilterIndex] = {
        ...newFilters[existingTextFilterIndex],
        value: value,
      };
    } else {
      newFilters.push({
        type: type,
        value: value,
      });
    }
    setFilters(newFilters.filter((f) => !!f.value));
  };

  return (
    <SearchContext.Provider
      value={{
        filters,
        items: itemsList,
        onSelected,
        onFilterChange,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
