import style from "./move-canvas-toast.module.css";

export default function MoveCanvasToast({ content, onClose }: { content: JSX.Element; onClose?: () => void }) {
  function renderCloseIcon() {
    return (
      <svg
        className={style.close}
        onClick={onClose}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9697 4.96967C14.2626 4.67678 14.7374 4.67678 15.0303 4.96967C15.3232 5.26256 15.3232 5.73744 15.0303 6.03033L11.0607 10L15.0303 13.9697C15.3232 14.2626 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2626 15.3232 13.9697 15.0303L10 11.0607L6.03033 15.0303C5.73744 15.3232 5.26256 15.3232 4.96967 15.0303C4.67678 14.7374 4.67678 14.2626 4.96967 13.9697L8.93934 10L4.96967 6.03033C4.67678 5.73744 4.67678 5.26256 4.96967 4.96967C5.26256 4.67678 5.73744 4.67678 6.03033 4.96967L10 8.93934L13.9697 4.96967Z"
          fill="white"
        />
      </svg>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.content}>
        {content}
        {renderCloseIcon()}
      </div>
    </div>
  );
}
