import React from "react";
import { SearchFilters } from "frontend/boards-grid/search-section/components/search-filters/search-filters";
import { SearchList } from "frontend/boards-grid/search-section/components/search-list/search-list";
import { useSearchContext } from "frontend/boards-grid/search-section/context/search-context";
import { getFilterValue } from "frontend/boards-grid/search-section/utils";
import styles from "./search-container.module.css";

export const SearchContainer = () => {
  const { filters } = useSearchContext();
  const hasNoValue = filters.map((f) => getFilterValue(f)).filter((v) => v).length === 0;

  return (
    <div className={styles.searchContainer}>
      <SearchFilters />
      {hasNoValue && <SearchList type="recent" />}
      <SearchList type="all" showTitle={hasNoValue} />
    </div>
  );
};
