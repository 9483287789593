import React, { useEffect, useRef, useState } from "react";
import { SearchBar } from "../search-section/components/search-bar/search-bar";
import { Floater } from "frontend/ui-components/floaters/floater";
import { SearchContainer } from "./components/search-container/search-container";
import { useEventListener, useOnClickOutside } from "usehooks-ts";
import { SearchContextProvider } from "./context/search-context";
import tracking from "frontend/tracking";
import { SEARCHBAR_TRACKING_ACTIONS } from "./events";
import styles from "./search-section.module.css";

export const SearchSection = () => {
  const searchBarRef = useRef<HTMLDivElement>(null);
  const floaterRef = useRef<HTMLDivElement>(null);
  const [isContainerOpen, setIsContainerOpen] = useState(false);

  useEventListener(
    "click",
    () => {
      setIsContainerOpen(true);
    },
    searchBarRef
  );

  useOnClickOutside(floaterRef, () => {
    setIsContainerOpen(false);
  });

  useEffect(() => {
    if (isContainerOpen) {
      tracking.trackAnalyticsEvent(SEARCHBAR_TRACKING_ACTIONS.searchbar_clicked);
    }
  }, [isContainerOpen]);

  return (
    <SearchContextProvider>
      <div className={styles.wrapper}>
        <SearchBar ref={searchBarRef} isOpen={isContainerOpen} />
        {isContainerOpen && (
          <Floater
            relativeTo={searchBarRef}
            ref={floaterRef}
            side={"bottom"}
            boundary="none"
            margin={-10}
            extraStyle={{
              zIndex: 500,
            }}
          >
            <SearchContainer />
          </Floater>
        )}
      </div>
    </SearchContextProvider>
  );
};
