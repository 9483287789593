import React from "react";
import { OWNER_OPTIONS } from "frontend/boards-grid/search-section/consts";
import styles from "./owner-filter.module.css";
import commonSyles from "../search-common.module.css";

type OwnerFilterProps = {
  onOwnerSelected: (owner: string) => void;
};

export const OwnerFilter = ({ onOwnerSelected }: OwnerFilterProps) => {
  return (
    <div className={styles.wrapper}>
      {OWNER_OPTIONS.map((option) => {
        return (
          <div key={option} className={commonSyles.option} onClick={() => onOwnerSelected(option)}>
            {option}
          </div>
        );
      })}
    </div>
  );
};
