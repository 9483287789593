import React from "react";
import { FixedSizeList as List } from "react-window";
import { SearchItem } from "frontend/boards-grid/search-section/components/search-item/search-item";
import { useSearchContext } from "frontend/boards-grid/search-section/context/search-context";
import { navigateToBoard } from "frontend/boards-grid/boards-grid-utils";
import tracking from "frontend/tracking";
import { SEARCHBAR_TRACKING_ACTIONS } from "frontend/boards-grid/search-section/events";
import styles from "./search-list.module.css";

type SearchListProps = {
  type: "recent" | "all";
  showTitle?: boolean;
};

const ITEM_HEIGHT = 55;
const TOTAL_HEIGHT = 4.5 * ITEM_HEIGHT;

export const SearchList = ({ type, showTitle = true }: SearchListProps) => {
  const { items, onSelected } = useSearchContext();
  const usedItems = type === "all" ? items.all : items.recent;

  if (usedItems.length === 0 && type === "recent") {
    return null;
  }

  if (usedItems.length === 0) {
    return <div className={styles.noBoards}>No boards found.</div>;
  }

  const allHeight = !showTitle && type === "all" ? 353 : TOTAL_HEIGHT;

  return (
    <>
      {showTitle && <div className={styles.recentTitle}>{type === "all" ? "All" : "Recent"}</div>}

      <List
        height={type === "all" ? allHeight : usedItems.length * ITEM_HEIGHT}
        itemCount={usedItems.length}
        width={580}
        itemSize={ITEM_HEIGHT}
        className={styles.wrapper}
      >
        {({ index, style }) => {
          const { documentId, name, lastUpdated, breadcrumbs, isOwner } = usedItems[index];
          return (
            <SearchItem
              key={index + documentId + type}
              style={style}
              documentId={documentId}
              name={name}
              lastUpdated={lastUpdated}
              breadcrumbs={breadcrumbs}
              isOwner={isOwner}
              onClick={(documentId) => {
                onSelected(documentId);
                tracking.trackAnalyticsEvent(SEARCHBAR_TRACKING_ACTIONS.searchbar_canvas_opened, {
                  documentId,
                });
                navigateToBoard({
                  documentId: documentId,
                  name: name,
                });
              }}
            />
          );
        }}
      </List>
    </>
  );
};
