import style from "./initialsIcon.module.css";
import consts from "shared/consts";

export function InitialsIcon({ text, list }: { text: string; list: any[] }) {
  const index = list.findIndex((obj) => {
    return Object.values(obj).some((value) => value === text);
  });
  const words = text.split(" ");
  const initials = words
    ?.map((word) => word[0])
    .join("")
    .toUpperCase()
    .substring(0, 2);
  return (
    <div
      className={style.initialsIcon}
      style={{ backgroundColor: consts.TEAM_PICKER_COLOR_PALETTE[index % consts.TEAM_PICKER_COLOR_PALETTE.length] }}
    >
      <span>{initials}</span>
    </div>
  );
}
