import React, { ReactNode, useRef, useState } from "react";
import cn from "classnames";
import { Floater } from "frontend/ui-components/floaters/floater";
import { useOnClickOutside } from "usehooks-ts";
import { ChevronDown } from "frontend/icons/chevron";
import styles from "./base-filter.module.css";

type BaseFilterProps = {
  text: string;
  children?: ({}: { closePopup: () => void }) => ReactNode;
};

export const BaseFilter = ({ text, children }: BaseFilterProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const floaterRef = useRef<HTMLDivElement>(null);
  const [isContainerOpen, setIsContainerOpen] = useState(false);

  useOnClickOutside(floaterRef, () => {
    setIsContainerOpen(false);
  });

  return (
    <div ref={buttonRef}>
      <div
        onClick={() => setIsContainerOpen(!isContainerOpen)}
        className={cn(styles.wrapper, {
          [styles.selected]: isContainerOpen,
        })}
      >
        {text} <ChevronDown />
      </div>
      {isContainerOpen && (
        <Floater
          relativeTo={buttonRef}
          ref={floaterRef}
          side={"bottom"}
          margin={-5}
          extraStyle={{
            zIndex: 1,
            transform: `translateX(calc(50% - ${(buttonRef.current?.clientWidth ?? 112) / 2}px))`,
          }}
        >
          {children?.({
            closePopup: () => {
              setIsContainerOpen(false);
            },
          })}
        </Floater>
      )}
    </div>
  );
};
