import React from "react";
import cssstyle from "./board-card-wrapper.module.css";

export function BoardCardWrapper({ onClick, children, style = null }: { onClick?: any; children: any; style?: any }) {
  return (
    <div className={cssstyle.container} onClick={onClick} style={style}>
      {children}
    </div>
  );
}
