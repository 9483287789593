import useCanvasTemplates from "frontend/hooks/use-canvas-templates";
import { CanvasTemplateMinimal, CanvasTemplateTag, TemplatePermission } from "shared/datamodel/schemas";
import LoadedState from "frontend/state/loadedState";
import { getRecommendedHomeTagOrderedByPreviewOrder } from "utils/template-tag-utils";
import { useFeatureFlag } from "./use-feature-flag/use-feature-flag";
import { useAtomValue } from "jotai";
import { userAtom } from "state-atoms";
import { useEffect } from "react";
import consts from "shared/consts";
import { getQueryParam } from "utils/navigator-utils";

const EXPLORE_TEMPLATE_NAMES = [
  "Org Chart",
  "Flowchart",
  "Role & Department Org Chart",
  "Meeting Agenda",
  "Flowing Mind Map",
  "Career progression paths",
  "Brainstorm",
  "Timeline",
  "Fishbone Diagram",
  "Scalability & Growth",
  "Venn Diagram",
];

const MIN_DISPLAYED_TEMPLATES = 8;

function getDefaultHomeRecommendedTemplates(templates: CanvasTemplateMinimal[]) {
  return templates
    .filter((template) => EXPLORE_TEMPLATE_NAMES.includes(template.name))
    .sort((a, b) => EXPLORE_TEMPLATE_NAMES.indexOf(a.name) - EXPLORE_TEMPLATE_NAMES.indexOf(b.name));
}

function getAdminConfiguredHomeRecommendedTagTemplates(
  allTemplates: CanvasTemplateMinimal[],
  defaultHomeRecommendedTemplates: CanvasTemplateMinimal[],
  recommendedHomeTag: CanvasTemplateTag
) {
  try {
    const orderedTagTemplateIds = (recommendedHomeTag.exampleTemplates ?? []).map(String);
    const orderedTagTemplateIdsSet = new Set(orderedTagTemplateIds);

    const tagTemplates: string[] = [
      ...orderedTagTemplateIds,
      ...recommendedHomeTag.templates
        .filter((templateId) => !orderedTagTemplateIdsSet.has(String(templateId)))
        .map(String),
    ];

    const templatesMap = new Map(allTemplates.map((template) => [template.id, template]));

    const homeRecommendedTemplates = tagTemplates
      .map((templateId) => templatesMap.get(templateId))
      .filter(Boolean) as CanvasTemplateMinimal[];

    if (homeRecommendedTemplates.length < MIN_DISPLAYED_TEMPLATES) {
      const homeRecommendedTemplatesIdsSet = new Set(homeRecommendedTemplates.map((template) => template.id));

      const remainingTemplates = defaultHomeRecommendedTemplates.filter(
        (template) => !homeRecommendedTemplatesIdsSet.has(template.id)
      );

      homeRecommendedTemplates.push(...remainingTemplates);
    }

    return homeRecommendedTemplates;
  } catch (error) {
    return defaultHomeRecommendedTemplates;
  }
}

export default function useHomeRecommendedTemplates() {
  const user = useAtomValue(userAtom);
  const { templates, hiddenTags = [], loadingState, forceReload } = useCanvasTemplates();

  useEffect(() => {
    const paymentCompletedUrlParams = getQueryParam(consts.URL_QUERY_PARAMS.SETUP_INTENT);

    if (paymentCompletedUrlParams) {
      forceReload();
    }
  }, [user?.planInfo?.plan_id]);

  const isHomeRecommendedTemplatesEnabled = useFeatureFlag("admin-configurable-recommended-home-templates");

  if (loadingState === LoadedState.loading || !templates) return null;

  const inMarketplaceTemplates = templates.filter((template) => template.inMarketplace);

  const defaultHomeRecommendedTemplates = getDefaultHomeRecommendedTemplates(inMarketplaceTemplates);

  const recommendedHomeTag = getRecommendedHomeTagOrderedByPreviewOrder(hiddenTags);

  const showAdminConfiguredTemplates = isHomeRecommendedTemplatesEnabled && recommendedHomeTag;

  return showAdminConfiguredTemplates
    ? getAdminConfiguredHomeRecommendedTagTemplates(
        inMarketplaceTemplates,
        defaultHomeRecommendedTemplates,
        recommendedHomeTag
      )
    : defaultHomeRecommendedTemplates;
}
