import React from "react";

export const SearchIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "20px", height: "20px", flex: "none" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.47254 10.126C2.47254 6.02306 5.73286 2.69697 9.75466 2.69697C13.7765 2.69697 17.0368 6.02306 17.0368 10.126C17.0368 14.2289 13.7765 17.555 9.75466 17.555C5.73286 17.555 2.47254 14.2289 2.47254 10.126ZM9.75466 0.730469C4.66826 0.730469 0.544922 4.93699 0.544922 10.126C0.544922 15.315 4.66826 19.5215 9.75466 19.5215C12.0274 19.5215 14.1078 18.6817 15.714 17.2897L19.7664 21.4238L21.1295 20.0333L17.0456 15.8671C18.2486 14.2794 18.9644 12.288 18.9644 10.126C18.9644 4.93699 14.8411 0.730469 9.75466 0.730469Z"
        fill="#AFB3BF"
      />
    </svg>
  );
};
