import { RecentSelected } from "frontend/boards-grid/search-section/types";
import { useLocalStorage } from "react-use";

const SEARCH_KEY = "search-recent-selected";

export const MAX_RECENT_ITEMS = 6;

function sortRecentSelections(recentSelections: RecentSelected[], selectedDocumentId: string): RecentSelected[] {
  let found = false;
  // Map and update the array, checking if the documentId exists
  const updatedSelections = recentSelections.map((item) => {
    if (item.documentId === selectedDocumentId) {
      found = true;
      return {
        ...item,
        selectedAt: new Date(), // Update selectedAt for the given documentId to now
      };
    }
    return {
      ...item,
      selectedAt: new Date(item.selectedAt), // Ensure selectedAt is a Date object
    };
  });

  // If the documentId is not found, add it to the array
  if (!found) {
    updatedSelections.push({
      documentId: selectedDocumentId,
      selectedAt: new Date(), // Set current time for new document
    });
  }

  // Sort descending by selectedAt
  return updatedSelections.sort((a, b) => b.selectedAt.getTime() - a.selectedAt.getTime());
}

export const useRecentSelected = () => {
  const [value, setValue] = useLocalStorage<RecentSelected[]>(SEARCH_KEY, []);

  const onSelected = (documentId: string) => {
    const newArray = sortRecentSelections(value ?? [], documentId);
    setValue(newArray.slice(0, MAX_RECENT_ITEMS));
  };

  return { recentSelected: value?.slice(-1 * MAX_RECENT_ITEMS) ?? [], onSelected };
};
