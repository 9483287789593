import React from "react";
import { BaseFilter } from "frontend/boards-grid/search-section/components/search-filters/base-filter/base-filter";
import { OwnerFilter } from "frontend/boards-grid/search-section/components/search-filters/owner-filter/owner-filter";
import { DatesFilter } from "frontend/boards-grid/search-section/components/search-filters/dates-filter/dates-filter";
import { useSearchContext } from "frontend/boards-grid/search-section/context/search-context";
import styles from "./search-filters.module.css";

export const SearchFilters = () => {
  const { onFilterChange } = useSearchContext();

  return (
    <div className={styles.wrapper}>
      <BaseFilter text="Owner">
        {({ closePopup }) => {
          return (
            <OwnerFilter
              onOwnerSelected={(owner) => {
                onFilterChange("owner", owner);
                closePopup();
              }}
            />
          );
        }}
      </BaseFilter>
      <BaseFilter text="By Date">
        {({ closePopup }) => {
          return (
            <DatesFilter
              onDateSelected={(date) => {
                onFilterChange("date", date);
                closePopup();
              }}
            />
          );
        }}
      </BaseFilter>
    </div>
  );
};
